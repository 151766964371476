import React from 'react';
import { Container } from 'react-bootstrap';


const About = () => {
 
    return (
        <Container style={{maxWidth: "1000px"}}>
            <h1>О нас</h1>
<div>
            <p>
            ТД Агромашкомплект осуществляет
реализацию тракторных запчастей, ведущих заводов
производителей . 
<p>
<br></br>
Запрос прайса по электронной почте <a href="mailto:amk-yar@rambler.ru" >amk-yar@rambler.ru</a>
</p>
Кроме этого ТД АгроМашКомплект готов проконсультировать вас по любому вопросу, касающемуся подбору ремкомплекта и комплектующих. 
Специалисты отдела продаж обеспечивают индивидуальный подход к каждому покупателю.   
Предлагается гибкая система скидок и отсрочка платежа  для оптовых покупателей. 
Импорт запчастей и комплектующих производства предприятий Украины, Польши, Чехии, Китая. 
Доставка транспортными компаниями.
            </p>
            </div>
        </Container>
    );
};

export default About;